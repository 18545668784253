<template>
  <b-row class="sub-table">
    <b-col>
      <div class="card">
        <div class="card-body p-0">
          <table class="table table-sm no-border">
            <tbody>
              <tr class="bg-gray">
                <th></th>
                <template v-for="(item, idx) in PAYMENT_CODE">
                  <th
                    :key="idx"
                    v-if="
                      ['00', '01', '02', '03', '04', '05', '07', '08', '09'].indexOf(
                        item.cd_value
                      ) > -1
                    "
                  >
                    {{ item.cd_name_kr }}
                  </th>
                </template>
                <th>
                  <h5 style="margin-bottom:0"><span class="badge bg-danger">총합</span></h5>
                </th>
              </tr>
              <template v-for="(item, idx) in totalStat">
                <tr :key="idx">
                  <th rowspan="2">{{ convertVoucherType(item.pr_type) }}</th>
                  <td>{{ item.c_01 | commaFilter }} 건</td>
                  <td>{{ item.c_02 | commaFilter }} 건</td>
                  <td>{{ item.c_03 | commaFilter }} 건</td>
                  <td>{{ item.c_04 | commaFilter }} 건</td>
                  <td>{{ item.c_05 | commaFilter }} 건</td>
                  <td>{{ item.c_07 | commaFilter }} 건</td>
                  <td>{{ item.c_08 | commaFilter }} 건</td>
                  <td>{{ item.c_09 | commaFilter }} 건</td>
                  <td>{{ item.c_sum | commaFilter }} 건</td>
                </tr>
                <tr>
                  <td>{{ item.s_01 | commaFilter }} 원</td>
                  <td>{{ item.s_02 | commaFilter }} 원</td>
                  <td>{{ item.s_03 | commaFilter }} 원</td>
                  <td>{{ item.s_04 | commaFilter }} 원</td>
                  <td>{{ item.s_05 | commaFilter }} 원</td>
                  <td>{{ item.s_07 | commaFilter }} 원</td>
                  <td>{{ item.s_08 | commaFilter }} 원</td>
                  <td>{{ item.s_09 | commaFilter }} 원</td>
                  <td>{{ item.s_sum | commaFilter }} 원</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'dayStat',
  props: ['totalStat', 'PAYMENT_CODE', 'convertVoucherType'],
  data() {
    return {
      isBusy: false
    }
  }
}
</script>

<style scoped>
td {
  white-space: nowrap;
}
.sub-table th {
  text-align: center;
}
</style>
